import "./App.css";

import React, { useState } from "react";
import {
  CssBaseline,
  Container,
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
  Button,
  AppBar,
  Toolbar,
  Link,
} from "@mui/material";
import axios from "axios";

const PING_URL = process.env.REACT_APP_BASE_URL + "/api/ping/";
const PRODUCTS_URL = process.env.REACT_APP_BASE_URL + "/api/products/";
const CREATE_NEW_SESSION_URL =
  process.env.REACT_APP_BASE_URL + "/api/create_new_session/";
const GET_NEW_PRODUCT_URL =
  process.env.REACT_APP_BASE_URL + "/api/get_next_product/";

function App() {
  const [gender, setGender] = useState("Unisex");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100);
  const [sessionId, setSessionId] = useState(null);
  const [firstProduct, setFirstProduct] = useState(null);

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handlePriceChange = (event, newValue) => {
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(CREATE_NEW_SESSION_URL, {
        min_price: minPrice,
        max_price: maxPrice,
        gender: gender,
      });

      const { session_id, first_product_to_show } = response.data;
      setSessionId(session_id);

      const productResponse = await axios.get(
        `${PRODUCTS_URL + first_product_to_show}`
      );
      setFirstProduct(productResponse.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography variant="h5" gutterBottom>
          Gender
        </Typography>
        <RadioGroup
          aria-label="gender"
          name="gender"
          value={gender}
          onChange={handleGenderChange}
        >
          <FormControlLabel value="Men" control={<Radio />} label="Men" />
          <FormControlLabel value="Women" control={<Radio />} label="Women" />
          <FormControlLabel value="Unisex" control={<Radio />} label="Unisex" />
        </RadioGroup>
        <Typography id="price-range-slider" variant="h5" gutterBottom>
          Price Range
        </Typography>
        <Slider
          value={[minPrice, maxPrice]}
          onChange={handlePriceChange}
          valueLabelDisplay="auto"
          aria-labelledby="price-range-slider"
          min={0}
          max={300}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Start
        </Button>
        {sessionId && firstProduct && (
          <Paper elevation={3} style={{ padding: "20px", margin: "20px 0" }}>
            <Typography variant="h5" gutterBottom>
              Product Details
            </Typography>
            <Typography variant="h6" gutterBottom>
              {firstProduct.title}
            </Typography>
            <Typography gutterBottom>{firstProduct.description}</Typography>
          </Paper>
        )}
      </Container>
    </div>
  );
}

export default App;
